import { RouteConfig } from "./types";
import HomePage from "../pages/HomePage";
import SignInPage from "../pages/SignInPage";
import SignUpPage from "../pages/SignUpPage";
import NavbarTopLayout from "../layouts/NavbarTopLayout";
import UsersPage from "../pages/Admin/UsersPage";
import AccountSettingsPage from "../pages/AccountSettingsPage";
import UserProfilePage from "../pages/UserProfilePage";
import RemyPage from "../pages/RemyPage";
import QuotesPage from "../pages/QuotesPage";

export const routesConfig: RouteConfig[] = [
  {
    adminRequired: false,
    authenticatedRequired: false,
    path: "/signUp",
    component: SignUpPage,
    displayName: "Sign Up",
  },
  {
    adminRequired: false,
    authenticatedRequired: false,
    path: "/signIn",
    component: SignInPage,
    displayName: "Sign In",
  },
  {
    adminRequired: true,
    authenticatedRequired: true,
    path: "/admin/users",
    layout: NavbarTopLayout,
    component: UsersPage,
    displayName: "Users",
    dropdownGroup: "Admin",
  },
  {
    adminRequired: false,
    authenticatedRequired: true,
    path: "/home",
    layout: NavbarTopLayout,
    component: HomePage,
    displayName: "Home",
  },
  {
    adminRequired: false,
    authenticatedRequired: true,
    path: "/settings",
    layout: NavbarTopLayout,
    component: AccountSettingsPage,
    displayName: "Account Settings",
    right: true,
  },
  {
    adminRequired: false,
    authenticatedRequired: true,
    path: "/users/:uid",
    params: {
      uid: "me",
    },
    layout: NavbarTopLayout,
    component: UserProfilePage,
    displayName: "My Profile",
  },
  {
    adminRequired: false,
    authenticatedRequired: true,
    path: "/remy",
    layout: NavbarTopLayout,
    component: RemyPage,
    displayName: "Rémy",
  },
  {
    adminRequired: false,
    authenticatedRequired: true,
    path: "/quotes",
    layout: NavbarTopLayout,
    component: QuotesPage,
    displayName: "Quotes",
  },
  {
    path: "/",
    redirect: "/admin/users",
    authenticatedRequired: true,
    adminRequired: true,
  },
  {
    path: "/",
    redirect: "/home",
    authenticatedRequired: true,
    adminRequired: false,
  },
  {
    path: "/",
    redirect: "/signIn",
    authenticatedRequired: false,
    adminRequired: false,
  },
];
