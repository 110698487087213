import React, { createContext, useContext, useState, useEffect } from "react";
import produce from "immer";
export interface AppState {
  burgerMenu: { [key: string]: { collapsed: boolean } };
}

interface AppContext {
  initialized: boolean;
  state: AppState;
  setState: React.Dispatch<React.SetStateAction<AppState>>;
  toggleBurgerMenu: (path: string) => void;
  addError: (error: { message: string; code: string }) => void;
}

const initialAppState: AppState = {
  burgerMenu: { root: { collapsed: true }, Admin: { collapsed: true } },
};

const AppContext = createContext<AppContext | undefined>(undefined);

const AppProvider = (props: any) => {
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState(initialAppState);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized, setInitialized]);

  const toggleBurgerMenu = (path: string) => {
    setState((curState) =>
      produce(curState, (draft) => {
        draft.burgerMenu[path].collapsed = !curState.burgerMenu[path].collapsed;
      })
    );
  };

  const addError = (error: { message: string; code: string }) => {
    console.log("error :>> ", error);
  };

  const value = { initialized, state, setState, toggleBurgerMenu, addError };

  return <AppContext.Provider value={value} {...props} />;
};

function useAppContext() {
  return useContext(AppContext)!;
}

export { AppProvider, useAppContext };
