import React from "react";
import Quote from "../../components/Quote";

interface Props {}

const QuotesPage = (props: Props) => {
  const quotes = [
    {
      quote: `Repressions in the political and in the psycho-analytic senses thus go hand in hand. And wherever psycho-analytic repression in any marked form takes place, there is no genuine happiness.`,
      author: "Bertrand Russell",
      from: "Conquest of Happiness",
    },
    {
      quote: `The typical unhappy man is one who, having been deprived in youth of some normal satisfaction, has come to value this one kind of satisfaction more than any other, and has therefore given to his life a one-sided direction, together with a quite undue emphasis upon the achievement as opposed to the activities connected with it. There is, however, a further development which is very common in the present day. A man may feel so completely thwarted that he seeks no form of satisfaction, but only distraction and oblivion. He then becomes a devotee of "pleasure". That is to say, he seeks to make life bearable by becoming less alive. Drunkenness, for example, is temporary suicide; the happiness that it brings is merely negative, a momentary cessation of unhappiness. The narcissist and the megalomaniac believe that happiness is possible, though they may adopt mistaken means of achieving it; but the man who seeks intoxication, in whatever form, has given up hope except in oblivion, In his case, the first thing to be done is to persuade him that happiness is desirable. Men who are unhappy, like men who sleep badly, are always proud of the fact. Perhaps their pride is like that of the fox who had lost his tail; if so, the way to cure it is to point out to them how they can grow a new tail. Very few men, I believe, will deliberately choose unhappiness if they see a way of being happy.`,
      author: "Bertrand Russell",
      from: "Conquest of Happiness",
    },
    {
      quote: `The man who acquires easily things for which he feels only a very moderate desire concludes that the attainment of desire does not bring happiness. If he is of a  philosophic disposition, he concludes that human life is essentially wretched, since the man who has all he wants is still unhappy. He forgets that to be without some of the things you want is an indispensable part of happiness`,
      author: "Bertrand Russell",
      from: "Conquest of Happiness",
    },
    {
      quote: `I have by no means lost my belief in love, but the kind of love that I can believe in is not the kind the Victorian admired; it is adventurous and open-eyed, and, while it gives knowledge of good, it does not involve forgetfulness of evil, nor does it pretend to be sanctified or holy. The attribution of these qualities to the kind of love that was admired was an outcome of the sex taboo. The Victorian was profoundly convinced that most sex is evil, and had to attach exaggerated adjectives to the kind of which he could approve.`,
      author: "Bertrand Russell",
      from: "Conquest of Happiness",
    },
    {
      quote: `Man depends upon co-operation and has been provided by nature, somewhat inadequately, it is true, with the instinctive apparatus out of which the friendliness required for co-operation can spring. Love is the first and commonest form of emotion leading to co-operation, and those who have experienced love with any intensity will not be content with a philosophy that supposes their highest good to be independent of that of the person loved.In this respect parental feeling is even more powerful, but a parental feeling at its best is the result of love between the parents. I do not pretend that love in its highest form is common, but I do maintain that in its highest form it reveals values which must otherwise remain unknown and has itself a value which is untouched by skepticism although skeptics who are incapable of it may falsely attribute their incapacity to their skepticism.`,
      author: "Bertrand Russell",
      from: "Conquest of Happiness",
    },
    {
      quote: `John Watson: "Godfather, we'd like you to be godfather".

      Sherlock Holmes: "God is a ludicrous fiction, dreamt up by inadequates who abnegate all responsibility to an invisible magic friend."

      John Watson: "Yeah, but there'll be cake. Will you do it?"

      Sherlock Holmes: "I'll get back to you."`,
      author: "Sherlock (The TV Show)",
      from: "Season 4 Episode 1: ‘The Six Thatchers’",
      noQuotes: true,
    },
    {
      quote: `The single biggest psychological disposition is the ability to endure unbelievable amounts of daily grinding frustration. I don’t know any other discipline like it.`,
      author: "Clive Thompson",
      from: "Meet the People Coding Our World",
      src:
        "https://onezero.medium.com/meet-the-people-coding-our-world-1d2a482e2e77",
    },

    //   quote: ``,
    //   author: "",
    // },
  ];
  return (
    <div style={{ height: "100%", overflow: "auto", paddingTop: 20 }}>
      <div className="container is-fluid">
        <h4 className="subtitle is-4">
          This page has some interesting quotes I find thought-provoking
        </h4>
        <div className="section">
          <div>
            {quotes.map((q, i) => (
              <Quote key={i} {...q}></Quote>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotesPage;
