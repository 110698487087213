import React from "react";
import { useField } from "formik";

interface Props {
  name: string;
  label?: string;
}

const ReadOnlyTextInput = ({ name, label = name }: Props) => {
  const [field] = useField(name);

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control">
            <input
              className="input is-static"
              type="text"
              readOnly
              value={field.value}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyTextInput;
