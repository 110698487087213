import React from "react";
import SignInForm from "../../components/SignInForm2";
import { useAuthContext } from "../../contexts/Auth/AuthContext";
import { Link } from "react-router-dom";
import PageLayout from "../layouts/PageLayout";

interface Props {}

const SignInPage = (props: Props) => {
  const { signIn } = useAuthContext();

  const onSubmit = async (values: any) => {
    console.log("values :>> ", values);
    const success = await signIn(values);
    if (success) {
      console.log("Signed In Successfully");
    } else {
      console.log("Sign In failed");
    }
  };

  return (
    <PageLayout>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="columns is-marginless is-centered is-multiline"
      >
        <div className="column is-half">
          <SignInForm onSubmit={onSubmit}></SignInForm>
          <Link style={{ marginTop: 10, textAlign: "center" }} to="/signUp">
            I need an account.
          </Link>
        </div>
      </div>
    </PageLayout>
  );
};

export default SignInPage;
