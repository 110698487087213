import React, { PropsWithChildren } from "react";

interface Props {
  style?: React.CSSProperties;
}

const PageLayout = (props: PropsWithChildren<Props>) => {
  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export default PageLayout;
