import React from "react";
import { Link } from "react-router-dom";

interface Props {}

const HomePage = (props: Props) => {
  return (
    <div style={{ height: "100%", overflow: "auto", paddingTop: 20 }}>
      <div className="container is-fluid">
        <h3 className="title is-3">Welcome to my personal website</h3>
        <div className="section">
          <div className="content">
            <div>
              Things to do:
              <ul>
                <li>Create an account</li>
                <li>Upload an avatar</li>
                <li>
                  <Link to={"/remy"}>View pictures of my dog</Link>
                </li>
                <li>
                  <Link to={"/quotes"}>Read some interesting quotes</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
