import React from "react";

import "./App.scss";
import { AuthProvider } from "./contexts/Auth/AuthContext";
import Router from "./containers/routes/Router";
import { AppProvider } from "./contexts/AppContext";
import { AdminProvider } from "./contexts/Admin/AdminContext";
import { UsersProvider } from "./contexts/Users/UsersContext";

function App() {
  return (
    <AppProvider>
      <AuthProvider>
        <AdminProvider>
          <UsersProvider>
            <Router></Router>
          </UsersProvider>
        </AdminProvider>
      </AuthProvider>
    </AppProvider>
  );
}

export default App;
