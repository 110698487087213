import React from "react";
import Debug from "../../components/Debug";
import moment from "moment";

interface Props {}

const RemyPage = (props: Props) => {
  const images = {
    jpg: [
      { path: "/img/jpg/2.jpg" },
      { path: "/img/jpg/4.jpg" },
      { path: "/img/jpg/5.jpg" },
      { path: "/img/jpg/6.jpg" },
      { path: "/img/jpg/7.jpg" },
      { path: "/img/jpg/8.jpg" },
      { path: "/img/jpg/9.jpg" },
      { path: "/img/jpg/10.jpg" },
      { path: "/img/jpg/11.jpg" },
      { path: "/img/jpg/12.jpg" },
      { path: "/img/jpg/13.jpg" },
      { path: "/img/jpg/14.jpg" },
      { path: "/img/jpg/15.jpg" },
      { path: "/img/jpg/16.jpg" },
      { path: "/img/jpg/17.jpg" },
      { path: "/img/jpg/18.jpg" },
      { path: "/img/jpg/19.jpg" },
      { path: "/img/jpg/20.jpg" },
      { path: "/img/jpg/21.jpg" },
    ],
    png: [
      { path: "img/png/1.png" },
      { path: "img/png/2.png" },
      { path: "img/png/3.png" },
      { path: "img/png/4.png" },
      { path: "img/png/5.png" },
      { path: "img/png/6.png" },
      { path: "img/png/7.png" },
      { path: "img/png/8.png" },
      { path: "img/png/9.png" },
      { path: "img/png/10.png" },
    ],
    gif: [
      { path: "img/gif/1.gif" },
      { path: "img/gif/2.gif" },
      { path: "img/gif/3.gif" },
    ],
  };

  const imgHeight = 150;
  const imgWidth = 150;
  const imgMargin = 10;

  return (
    <div style={{ height: "100%", overflow: "auto", paddingTop: 20 }}>
      <div className="container is-fluid">
        <h4 className="subtitle is-4">
          This page is dedicated to my dog: <strong>Rémy</strong>
        </h4>
        <div className="section">
          <ul>
            <li>Name: Rémy</li>
            <li>Breed: Cockapoo (Cocker Spaniel & Poodle Mix)</li>
            <li>Color: Chocolate</li>
            <li>Gender: Male</li>
            <li>
              Age:{" "}
              {moment()
                .diff(moment("08-02-2018", "MM-DD-YYYY"), "years", false)
                .toString()}
            </li>
            <li>
              Birthday: {moment("08-02-2018", "MM-DD-YYYY").format("MMMM Do")}
            </li>
          </ul>
        </div>
        <div
          style={{
            margin: 0,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          {images.gif.map((img: { path: string }, i) => {
            return (
              <figure key={i} className={`is-${imgHeight}x${imgWidth}`}>
                <img
                  style={{
                    height: imgHeight,
                    width: imgWidth,
                    margin: imgMargin,
                  }}
                  src={process.env.PUBLIC_URL + img.path}
                ></img>
              </figure>
            );
          })}
          {images.png.map((img: { path: string }, i) => {
            return (
              <figure key={i} className={`is-${imgHeight}x${imgWidth}`}>
                <img
                  style={{
                    height: imgHeight,
                    width: imgWidth,
                    margin: imgMargin,
                  }}
                  src={process.env.PUBLIC_URL + img.path}
                ></img>
              </figure>
            );
          })}
          {images.jpg.map((img: { path: string }, i) => {
            return (
              <figure key={i} className={`is-${imgHeight}x${imgWidth}`}>
                <img
                  style={{
                    height: imgHeight,
                    width: imgWidth,
                    margin: imgMargin,
                  }}
                  src={process.env.PUBLIC_URL + img.path}
                ></img>
              </figure>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RemyPage;
