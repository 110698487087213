import React, { useEffect } from "react";
import { useUsersContext } from "../../contexts/Users/UsersContext";
import { useParams } from "react-router-dom";
import UserProfile from "../../components/UserProfile";
import LoadingPage from "./LoadingPage";

interface Props {}

const UserProfilePage = (props: Props) => {
  const { initialized, state, fetchUser } = useUsersContext();
  let { uid } = useParams();

  useEffect(() => {
    if (!(uid in state) && initialized) {
      fetchUser(uid);
    }
  }, [uid, initialized, fetchUser, state]);

  if (!(uid in state)) {
    return <LoadingPage></LoadingPage>;
  }

  const user = state[uid];
  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <article className="container">
        <section className="section">
          <UserProfile user={user}></UserProfile>
        </section>
      </article>
    </div>
  );
};

export default UserProfilePage;
