import React from "react";
import { useField } from "formik";
import classnames from "classnames";

interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
}

const TextInput = ({
  name,
  placeholder,
  label = name,
  readOnly = false,
}: Props) => {
  const [field] = useField(name);

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control">
            <input
              className={classnames("input", { "is-static": readOnly })}
              type="text"
              {...field}
              readOnly={readOnly}
              placeholder={placeholder}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default TextInput;
