import React, { PropsWithChildren } from "react";
import classnames from "classnames";

import { useAppContext } from "../../contexts/AppContext";
import { useAuthContext } from "../../contexts/Auth/AuthContext";
import { routesConfig } from "../routes/constants";
import { NavLink, useLocation } from "react-router-dom";
import { RouteConfig } from "../routes/types";
import { getRouteConfigTo } from "../routes/util";
import Debug from "../../components/Debug";
import { auth } from "firebase";

interface Props {}

const NavbarTopLayout = (props: PropsWithChildren<Props>) => {
  const {
    state: { burgerMenu },
    toggleBurgerMenu,
  } = useAppContext();

  const authContext = useAuthContext();
  const {
    signOut,
    state: { claims },
  } = authContext;

  const location = useLocation();

  const currentRouteConfigs = routesConfig.filter((rc) => {
    if (!rc.authenticatedRequired) return false;
    if (rc.adminRequired && !claims!.admin) return false;
    if (rc.redirect) return false;
    return true;
  });

  const dropdownsStart: { [key: string]: RouteConfig[] } = {
    any: [],
  };

  const dropdownsEnd: { [key: string]: RouteConfig[] } = {
    any: [],
  };

  for (const rc of currentRouteConfigs.filter((rc) => !rc.right)) {
    if (rc.dropdownGroup) {
      if (!(rc.dropdownGroup in dropdownsStart)) {
        dropdownsStart[rc.dropdownGroup] = [];
      }
      dropdownsStart[rc.dropdownGroup].push(rc);
    } else {
      dropdownsStart.any.push(rc);
    }
  }

  for (const rc of currentRouteConfigs.filter((rc) => rc.right)) {
    if (rc.dropdownGroup) {
      if (!(rc.dropdownGroup in dropdownsEnd)) {
        dropdownsEnd[rc.dropdownGroup] = [];
      }
      dropdownsEnd[rc.dropdownGroup].push(rc);
    } else {
      dropdownsEnd.any.push(rc);
    }
  }

  const entryToNavLink = ([path, rcs]: [string, RouteConfig[]]) => {
    const isActive = rcs.find((rc) => rc.path === location.pathname);

    if (path === "any") {
      const links = rcs.map((rc) => {
        return (
          <NavLink
            key={rc.path}
            className={classnames("navbar-item", { "is-active": isActive })}
            exact
            to={getRouteConfigTo(rc)}
            onClick={(e) => toggleBurgerMenu("root")}
          >
            {rc.displayName}
          </NavLink>
        );
      });
      return links;
    } else {
      return (
        <div
          key={path}
          className={classnames("navbar-item has-dropdown", {
            "is-active": !burgerMenu[path].collapsed,
          })}
        >
          <div
            className={classnames("navbar-link", {})}
            onClick={(e) => toggleBurgerMenu(path)}
          >
            {path}
          </div>

          {!burgerMenu[path].collapsed ? (
            <div className="navbar-dropdown">
              {rcs.map((rc) => (
                <NavLink
                  key={rc.path}
                  className={classnames("navbar-item", {
                    "is-active": isActive,
                  })}
                  exact
                  to={getRouteConfigTo(rc)}
                  onClick={(e) => {
                    toggleBurgerMenu("root");
                    toggleBurgerMenu(path);
                  }}
                >
                  {rc.displayName}
                </NavLink>
              ))}
            </div>
          ) : undefined}
        </div>
      );
    }
  };

  const navBarItemsStart: (JSX.Element | JSX.Element[])[] = Object.entries(
    dropdownsStart
  ).map(entryToNavLink);
  const navBarItemsEnd: (JSX.Element | JSX.Element[])[] = Object.entries(
    dropdownsEnd
  ).map(entryToNavLink);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <nav
        className="navbar is-warning"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item has-text-weight-bold is-family-primary">
            Welcome{" "}
            {authContext.state.currentUser?.displayName
              ? authContext.state.currentUser?.displayName
              : authContext.state.currentUser?.email}
          </div>

          <div
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={(e) => toggleBurgerMenu("root")}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        <div
          className={classnames("navbar-menu", {
            "is-active": !burgerMenu.root.collapsed,
          })}
        >
          <div className="navbar-start">{navBarItemsStart}</div>
          <div className="navbar-end">
            {navBarItemsEnd}
            <div className="navbar-item">
              <div className="buttons">
                <button
                  onClick={(e) => signOut()}
                  className="button is-primary"
                >
                  <strong>Sign out</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <Debug>{authContext.state}</Debug> */}
      {props.children}
    </div>
  );
};

export default NavbarTopLayout;
