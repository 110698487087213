import React, { Fragment } from "react";

interface Props {
  quote: string;
  author?: string;
  from?: string;
  src?: string;
  noQuotes?: boolean;
}

const Quote = ({ quote, author, from, src, noQuotes = false }: Props) => {
  const quoteArray = quote.split("\n");
  return (
    <blockquote style={{ margin: 0, marginBottom: 20 }}>
      <p
        style={{
          padding: 15,
          background: "#eee",
          borderRadius: 5,
        }}
      >
        {!noQuotes ? <span>&ldquo;</span> : undefined}
        {quoteArray.map((line, i) => {
          if (i === quoteArray.length - 1) {
            return line;
          }
          return (
            <Fragment key={i}>
              {line}
              <br />
            </Fragment>
          );
        })}
        {!noQuotes ? <span>&rdquo;</span> : undefined}
      </p>
      <footer>
        —{author || "Unknown"}
        {(from || src) && (
          <span>
            ,{" "}
            <cite>
              {from && from} {src && <a href={src}>{src}</a>}
            </cite>
          </span>
        )}
      </footer>
    </blockquote>
  );
};

export default Quote;
