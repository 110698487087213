import React, { PropsWithChildren } from "react";

interface Props {}

const Debug = (props: PropsWithChildren<Props>) => {
  return (
    <pre>
      <code>{JSON.stringify(props.children, undefined, 2)}</code>
    </pre>
  );
};

export default Debug;
