import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import Axios from "axios";
import { useAuthContext } from "../Auth/AuthContext";
import produce from "immer";

export interface User {
  uid: string;
  displayName: string;
  email: string;
  photoURL: string;
}

export interface UsersState {
  [key: string]: User;
}

interface UsersContext {
  initialized: boolean;
  state: UsersState;
  setState: React.Dispatch<React.SetStateAction<UsersState>>;
  fetchUser: (uid: string) => Promise<void>;
}

const initialUsersState: UsersState = {};

const UsersContext = createContext<UsersContext | undefined>(undefined);

const UsersProvider = (props: any) => {
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState(initialUsersState);
  const authContext = useAuthContext();

  useEffect(() => {
    const { currentUser } = authContext.state;
    if (authContext.initialized && !initialized && currentUser) {
      setState({
        me: {
          uid: currentUser.uid,
          displayName: currentUser.displayName || "No display name",
          email: currentUser.email || "No email",
          photoURL: currentUser.photoURL || "No photo",
        },
      });
      setInitialized(true);
    } else if (authContext.initialized && initialized && currentUser) {
      setState({
        me: {
          uid: currentUser.uid,
          displayName: currentUser.displayName || "No display name",
          email: currentUser.email || "No email",
          photoURL: currentUser.photoURL || "No photo",
        },
      });
    }
  }, [authContext, initialized, setInitialized]);

  const fetchUser = useCallback(
    async (uid: string) => {
      try {
        console.log("user context fetching user", uid);
        const response = await Axios.get(`/api/users/${uid}`, {
          headers: { Authorization: `Bearer ${authContext.state.token}` },
        });
        setState((curState) =>
          produce(curState, (draft) => {
            draft[response.data.uid] = response.data;
          })
        );
        setInitialized(true);
      } catch (err) {
        console.error(err);
      }
    },
    [authContext]
  );

  const value = { initialized, state, setState, fetchUser };

  return <UsersContext.Provider value={value} {...props} />;
};

function useUsersContext() {
  return useContext(UsersContext)!;
}

export { UsersProvider, useUsersContext };
