import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import classnames from "classnames";

import Debug from "./Debug";
import TextInput from "./inputs/TextInput";
import ImageInput from "./inputs/ImageInput";

export interface FormValues {
  uid: string;
  displayName?: string;
  email?: string;
  phoneNumber?: string;
  photoURL?: string | File;
}

interface Props {
  user: FormValues;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<boolean>;
}

const UserSettings = ({
  user: { uid, displayName, email, phoneNumber, photoURL },
  onSubmit,
}: Props) => {
  const initalFormValues: FormValues = {
    uid,
    displayName,
    email,
    phoneNumber,
    photoURL,
  };

  const placeholders: { [key: string]: string } = {
    uid: "UID",
    displayName: "Display Name",
    email: "Email",
    phoneNumber: "Phone",
  };

  const labels: { [key: string]: string } = {
    uid: "UID",
    displayName: "Display Name",
    email: "Email",
    phoneNumber: "Phone",
    photoURL: "Avatar",
  };

  const readOnlys: { [key: string]: boolean } = {
    uid: true,
    displayName: false,
    email: true,
    phoneNumber: true,
  };
  return (
    <Formik
      initialValues={initalFormValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        return (
          <Form>
            {(Object as any)
              .entries(formikProps.values)
              .map(([k, v]: [string, string]) => {
                if (k === "photoURL") {
                  return <ImageInput key={k} name={k} label={labels[k]} />;
                }
                return (
                  <TextInput
                    key={k}
                    name={k}
                    readOnly={readOnlys[k]}
                    label={labels[k]}
                    placeholder={placeholders[k]}
                  />
                );
              })}
            <div className="field is-horizontal">
              <div className="field-label" />
              <div className="field-body">
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      className={classnames("button is-primary", {
                        "is-loading": formikProps.isSubmitting,
                      })}
                      type="submit"
                      disabled={!formikProps.isValid || !formikProps.dirty}
                    >
                      Update
                    </button>
                  </div>

                  <div className="control">
                    <button
                      type="reset"
                      onClick={(e) => formikProps.resetForm()}
                      className={classnames("button is-link is-light", {
                        "is-loading": formikProps.isSubmitting,
                      })}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserSettings;
