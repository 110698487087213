import React from "react";
import { useAdminContext } from "../../../contexts/Admin/AdminContext";
import UserCard from "../../../components/UserCard";
import { useAuthContext } from "../../../contexts/Auth/AuthContext";

interface Props {}

const UsersPage = (props: Props) => {
  const {
    initialized,
    state: { users },
    deleteUser,
  } = useAdminContext();

  const {
    state: { currentUser },
  } = useAuthContext();

  if (!initialized) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="spinner" />
      </div>
    );
  }

  return (
    <div className="section" style={{ overflow: "auto" }}>
      {users.map((u, i) => {
        return (
          <div key={i}>
            <br />
            <UserCard
              key={i}
              showDelete={currentUser ? u.uid !== currentUser.uid : true}
              user={u}
              onDelete={deleteUser}
            ></UserCard>
          </div>
        );
      })}
    </div>
  );
};

export default UsersPage;
