import React from "react";
import { useField } from "formik";

interface Props {
  name: string;
  label?: string;
}

const ReadOnlyImageInput = ({ name, label = name }: Props) => {
  const [field] = useField(name);

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <figure className="image is-128x128">
              <img
                style={{ width: 128, height: 128 }}
                src={field.value}
                alt={label}
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyImageInput;
