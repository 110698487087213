import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from "firebase";
import Axios from "axios";

import firebaseConfig from "./config.json";
import { CustomClaims } from "./types";
import { useAppContext } from "../AppContext";
import produce from "immer";

console.log("Initializing App");
firebase.initializeApp(firebaseConfig);

export interface AuthState {
  currentUser?: firebase.User;
  token?: string;
  claims?: CustomClaims;
  authHandler?: firebase.Unsubscribe;
}

interface AuthContext {
  initialized: boolean;
  state: AuthState;
  setState: React.Dispatch<React.SetStateAction<AuthState>>;
  signIn: (values: { email: string; password: string }) => Promise<boolean>;
  signOut: () => Promise<void>;
  signUp: (values: { email: string; password: string }) => Promise<boolean>;
  refreshUser: () => Promise<void>;
  updateUser: (user: {
    displayName?: string;
    email?: string;
    phoneNumber?: string;
    photoURL?: string;
  }) => Promise<void>;
}

const initialAuthState: AuthState = {
  currentUser: undefined,
  token: undefined,
  claims: undefined,
  authHandler: undefined,
};

const AuthContext = createContext<AuthContext | undefined>(undefined);

const AuthProvider = (props: any) => {
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState(initialAuthState);
  const { addError } = useAppContext();

  useEffect(() => {
    if (!initialized && !state.authHandler) {
      console.log(`firebase.auth().onAuthStateChanged set`);
      const authHandler = firebase.auth().onAuthStateChanged(async (user) => {
        console.log("firebase.auth().onAuthStateChanged user :>> ", user);
        if (user) {
          let { token, claims } = await user.getIdTokenResult(true);
          if (!("admin" in claims)) {
            try {
              // Register claims
              await Axios.post(`/api/claims`, undefined, {
                headers: { Authorization: "Bearer " + token },
              });
            } catch (err) {
              console.error(err);
              addError(err);
            }
            firebase.auth().signOut();
          } else {
            // Force refresh of identity token and therefore get the new claims
            let updatedTokenResult = await user.getIdTokenResult(true);
            setState({
              currentUser: user,
              token: updatedTokenResult.token,
              claims: updatedTokenResult.claims,
            });
          }
        } else {
          setState({
            currentUser: undefined,
            token: undefined,
            claims: undefined,
          });
        }
        setInitialized(true);
      });
      setState((curState) =>
        produce(curState, (draft) => {
          draft.authHandler = authHandler;
        })
      );
    }
  }, [initialized, setInitialized, addError, state.authHandler]);

  const signIn = async (values: { email: string; password: string }) => {
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);
      if (userCredential && userCredential.user) {
        let { token, claims } = await userCredential.user.getIdTokenResult(
          true
        );
        setState({
          currentUser: userCredential.user,
          token,
          claims,
        });
      }
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  };

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  };

  const signUp = async (values: { email: string; password: string }) => {
    try {
      console.log("signing up");
      await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  };

  const refreshUser = async (user: {
    displayName: string;
    email: string;
    phoneNumber: string;
    photoURL: string;
  }) => {
    console.log("refreshing user");
    try {
      setState((curState) =>
        produce(curState, (draft) => {
          // draft.currentUser =
        })
      );
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  };

  const updateUser = async (user: {
    displayName?: string;
    email?: string;
    phoneNumber?: string;
    photoURL?: string;
  }) => {
    if (initialized && state.currentUser) {
      await state.currentUser.updateProfile(user);
      let { token, claims } = await state.currentUser.getIdTokenResult(true);
      setState({
        currentUser: state.currentUser,
        token,
        claims,
      });
    }
  };

  const value = {
    initialized,
    state,
    setState,
    signIn,
    signOut,
    signUp,
    refreshUser,
    updateUser,
  };
  return <AuthContext.Provider value={value} {...props} />;
};

function useAuthContext() {
  return useContext(AuthContext)!;
}

export { AuthProvider, useAuthContext };
