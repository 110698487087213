import React, { useRef, useEffect } from "react";
import { useField } from "formik";

interface Props {
  name: string;
  label?: string;
}

const ImageInput = ({ name, label = name }: Props) => {
  const [field, meta, helpers] = useField(name);
  const imgRef = useRef<HTMLImageElement>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      const objectURL = window.URL.createObjectURL(e.target.files[0]);
      helpers.setValue(e.target.files[0]);
      if (imgRef && imgRef.current) {
        imgRef.current.src = objectURL;
      }
    }
  };

  useEffect(() => {
    if (imgRef && imgRef.current) {
      if (field.value === undefined) {
        imgRef.current.src = "https://bulma.io/images/placeholders/128x128.png";
      } else if (typeof field.value === "string") {
        imgRef.current.src = field.value;
      }
    }
  }, [imgRef]);

  let fileName;
  if (field.value === undefined) {
    fileName = undefined;
  } else if (typeof field.value === "string") {
    fileName = field.value;
  } else {
    fileName = field.value.name;
  }

  return (
    <div className="field is-horizontal" key={name}>
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <figure className="image is-128x128">
              <img style={{ width: 128, height: 128 }} ref={imgRef} />
            </figure>
            <div className="file has-name ">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name={name}
                  onChange={onChange}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload" />
                  </span>
                  <span className="file-label">Choose a file…</span>
                </span>
                {fileName && <span className="file-name">{fileName}</span>}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageInput;
