import React from "react";
import Debug from "./Debug";

interface Props {
  user: User;
  showDelete: boolean;
  onDelete?: (uid: string) => Promise<void> | void;
}

interface User {
  uid: string;
  displayName: string;
  email: string;
  phoneNumber?: string;
  photoURL?: string;
}

const UserCard = ({ user, showDelete, onDelete = () => {} }: Props) => {
  return (
    <div className="card">
      <div className="card-content has-background-grey-lighter">
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              <img
                style={{ width: 48, height: 48 }}
                src={
                  user.photoURL
                    ? user.photoURL
                    : "https://bulma.io/images/placeholders/48x48.png"
                }
                alt="Avatar"
              />
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-4">{user.displayName}</p>
            <p className="subtitle is-6">{user.email}</p>
          </div>
          {showDelete ? (
            <div className="field">
              <p className="control">
                <button
                  style={{ overflow: "hidden" }}
                  className="button is-danger"
                  onClick={(e) => onDelete(user.uid)}
                >
                  Delete
                </button>
              </p>
            </div>
          ) : undefined}
        </div>

        <div className="content">
          <Debug>{user}</Debug>
        </div>
      </div>
    </div>
  );
};
export default UserCard;
