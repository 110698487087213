import React from "react";
import SignUpForm from "../../components/SignUpForm2";
import { useAuthContext } from "../../contexts/Auth/AuthContext";
import { Link, useHistory } from "react-router-dom";
import PageLayout from "../layouts/PageLayout";

interface Props {}

const SignUpPage = (props: Props) => {
  const { signUp } = useAuthContext();
  const history = useHistory();

  const onSubmit = async (values: any) => {
    console.log("values :>> ", values);
    const success = await signUp(values);
    if (success) {
      console.log("Signed Up Successfully");
      history.push("/signIn");
    } else {
      console.log("Sign Up failed");
    }
  };

  return (
    <PageLayout>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="columns is-marginless is-centered is-multiline"
      >
        <div className="column is-half">
          <SignUpForm onSubmit={onSubmit}></SignUpForm>

          <Link style={{ marginTop: 10, textAlign: "center" }} to="/signIn">
            I already have an account.
          </Link>
        </div>
      </div>
    </PageLayout>
  );
};

export default SignUpPage;
