import React from "react";
import { FormValues } from "../../components/UserSettings";
import { useAuthContext } from "../../contexts/Auth/AuthContext";
import { FormikHelpers } from "formik";
import firebase from "firebase";
import UserSettings from "../../components/UserSettings";

interface Props {}

const AccountSettingsPage = (props: Props) => {
  const {
    state: { currentUser },
    setState,
    updateUser,
  } = useAuthContext();

  if (!currentUser) {
    return <div></div>;
  }
  const { uid, displayName, email, phoneNumber, photoURL } = currentUser;
  const user = {
    uid: uid,
    email: email || "",
    displayName: displayName || "",
    phoneNumber: phoneNumber || "",
    photoURL: photoURL || "",
  };

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    try {
      console.log("submitted values :>> ", values);
      // Photo URL
      if (values.photoURL && typeof values.photoURL === "object") {
        const storageRef = firebase.storage().ref();
        const child = storageRef.child(`${currentUser.uid}/profilePicture`);
        const snapshot = await child.put(values.photoURL as File);
        console.log("snapshot.state :>> ", snapshot.state);
        if (snapshot.state === "success") {
          await updateUser({
            photoURL: await child.getDownloadURL(),
          });
        } else {
          throw new Error('child.put returned state !== "success"');
        }
      }

      // Display Name
      if (values.displayName) {
        await updateUser({
          displayName: values.displayName,
        });
      }
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  };

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <article className="container is-fluid">
        <section className="section">
          <UserSettings user={user} onSubmit={onSubmit}></UserSettings>
        </section>
      </article>
    </div>
  );
};

export default AccountSettingsPage;
