import React from "react";
import { Formik, Form } from "formik";

import Debug from "./Debug";
import ReadOnlyTextInput from "./inputs/ReadOnlyTextInput";
import ReadOnlyImageInput from "./inputs/ReadOnlyImageInput";

interface FormValues {
  uid: string;
  displayName?: string;
  email?: string;
  phoneNumber?: string;
  photoURL?: string;
}

interface Props {
  user: FormValues;
}

const UserProfile = ({
  user: { uid, displayName, email, phoneNumber, photoURL },
}: Props) => {
  const labels: { [key: string]: string } = {
    uid: "UID",
    displayName: "Display Name",
    email: "Email",
    phoneNumber: "Phone",
    photoURL: "Avatar",
  };

  return (
    <Formik
      initialValues={{ uid, displayName, email, phoneNumber, photoURL }}
      onSubmit={() => {}}
      enableReinitialize
    >
      {(formikProps) => {
        return (
          <Form>
            {(Object as any)
              .entries(formikProps.values)
              .map(([k, v]: [string, string]) => {
                if (k == "photoURL") {
                  return (
                    v && (
                      <ReadOnlyImageInput key={k} name={k} label={labels[k]} />
                    )
                  );
                }
                return (
                  v && <ReadOnlyTextInput key={k} name={k} label={labels[k]} />
                );
              })}
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserProfile;

// import React from "react";
// import { User } from "../contexts/Users/UsersContext";
// import { Form, Formik } from "formik";
// import TextInput from "./TextInput";
// import ImageInput from "./ImageInput";

// interface Props {
//   user: User;
// }

// const UserProfile = ({ user }: Props) => {
//   return (
//     <div>
//       <Formik
//         initialValues={{ ...user }}
//         onSubmit={() => {}}
//         enableReinitialize
//       >
//         <Form>
//           <TextInput name="uid" label="UID" readOnly isHorizontal />
//           {user.displayName ? (
//             <TextInput
//               name="displayName"
//               label="Display Name"
//               readOnly
//               isHorizontal
//             />
//           ) : null}
//           {user.email && (
//             <TextInput name="email" label="Email" readOnly isHorizontal />
//           )}
//           {user.photoURL && (
//             <ImageInput
//               name="photoURL"
//               defaultPhotoURL={user.photoURL}
//               label="Avatar"
//               readOnly
//               isHorizontal
//             />
//           )}
//         </Form>
//       </Formik>
//     </div>
//   );
// };

// export default UserProfile;
