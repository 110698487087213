import React, { HTMLProps } from "react";
import classnames from "classnames";
import { useField, useFormikContext } from "formik";

interface Props extends HTMLProps<HTMLInputElement> {
  name: string;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  isHorizontal?: boolean;
}

const TextInput = ({
  name,
  label,
  type,
  placeholder,
  readOnly = false,
  isHorizontal = false,
}: Props) => {
  const [field, meta] = useField(name);
  const { isSubmitting } = useFormikContext();
  return (
    <div className={classnames("field", { "is-horizontal": isHorizontal })}>
      {label && (
        <div className="field-label is-normal">
          <label className="label">{label}</label>
        </div>
      )}
      <div className="field-body">
        <div className="field">
          <div
            className={classnames("control", {
              "is-loading": isSubmitting,
            })}
          >
            <input
              className={classnames("input", { "is-static": readOnly })}
              type={type}
              {...field}
              readOnly={readOnly}
              placeholder={placeholder}
            />
          </div>
          {meta.touched && meta.error ? (
            <p className="help is-danger">{meta.error}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TextInput;
