import React from "react";
import { Route, Redirect } from "react-router-dom";

import { RouteConfig } from "./types";
import { AuthState } from "../../contexts/Auth/AuthContext";

export const createRoute = (routeConfig: RouteConfig, auth: AuthState) => {
  let key = routeConfig.path;
  const { currentUser, claims } = auth;

  const authenticated = currentUser !== undefined;
  const admin = claims && claims.admin;

  if (routeConfig.authenticatedRequired !== authenticated) {
    return undefined;
  }

  if (routeConfig.adminRequired && !admin) {
    return undefined;
  }

  if (routeConfig.redirect !== undefined) {
    return (
      <Route key={key} path={routeConfig.path}>
        <Redirect to={routeConfig.redirect} />
      </Route>
    );
  }

  if (routeConfig.component === undefined) {
    return (
      <Route key={key} path={routeConfig.path}>
        <Redirect to="/" />
      </Route>
    );
  }

  if (routeConfig.layout !== undefined) {
    return (
      <Route key={key} path={routeConfig.path}>
        <routeConfig.layout>
          <routeConfig.component {...routeConfig.props}></routeConfig.component>
        </routeConfig.layout>
      </Route>
    );
  }

  return (
    <Route key={key} path={routeConfig.path}>
      <routeConfig.component {...routeConfig.props}></routeConfig.component>
    </Route>
  );
};

export const getRouteConfigTo = (routeConfig: RouteConfig) => {
  let to = routeConfig.path;
  if (routeConfig.params !== undefined) {
    for (const [k, v] of Object.entries(routeConfig.params)) {
      to = to.replace(":" + k, v as string);
    }
  }
  return to;
};
