import React from "react";
import classnames from "classnames";
import { Formik, FormikHelpers, Form, FormikProps } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

import TextInput from "./TextInput";

interface FormValues {
  email?: string;
  password?: string;
}

interface Props {
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<any>;
}

const SignInForm = (props: Props) => {
  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};

    // email
    if (!values.email) {
      errors.email = "Email required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    // password
    if (!values.password) {
      errors.password = "Password required";
    }

    return errors;
  };

  const onSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    await props.onSubmit(values, formikHelpers);
    formikHelpers.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<FormValues>) => {
        return (
          <div>
            <Form>
              <div className="field">
                <div className="control has-icons-left has-icons-right">
                  <TextInput placeholder="Email" name="email" type="email" />
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <span className="icon is-small is-right">
                    <i className="fas fa-check" />
                  </span>
                </div>
              </div>
              <div className="field">
                <div className="control has-icons-left">
                  <TextInput
                    type="password"
                    placeholder="Password"
                    name="password"
                  />
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button
                    className={classnames("button is-success is-fullwidth", {
                      "is-loading": formikProps.isSubmitting,
                    })}
                    disabled={!formikProps.isValid || !formikProps.dirty}
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default SignInForm;
