import React from "react";

interface Props {
  progress?: number;
}

const LoadingPage = ({ progress }: Props) => {
  return (
    <section
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
        <div className="spinner" />
      </div>
      {progress !== undefined && (
        <progress
          style={{ width: "100%" }}
          className="progress"
          value={progress}
          max="100"
        />
      )}
    </section>
  );
};

export default LoadingPage;
