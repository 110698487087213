import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import { useAuthContext } from "../../contexts/Auth/AuthContext";
import { routesConfig } from "./constants";
import { createRoute } from "./util";
import LoadingPage from "../pages/LoadingPage";

interface Props {}

const Router = (props: Props) => {
  const { initialized, state } = useAuthContext();
  const routes = routesConfig.map((rc, i) => createRoute(rc, state));

  if (!initialized) {
    return <LoadingPage />;
  }
  return (
    <BrowserRouter>
      <Switch>{routes}</Switch>
    </BrowserRouter>
  );
};

export default Router;
