import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuthContext } from "../Auth/AuthContext";
import Axios from "axios";
import produce from "immer";

export interface AdminState {
  users: any[];
}

interface AdminContext {
  initialized: boolean;
  state: AdminState;
  setState: React.Dispatch<React.SetStateAction<AdminState>>;
  deleteUser: (uid: string) => Promise<void>;
}

const initialAdminState: AdminState = {
  users: [],
};

const AdminContext = createContext<AdminContext | undefined>(undefined);

const AdminProvider = (props: any) => {
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState(initialAdminState);
  const {
    state: { token, claims },
  } = useAuthContext();

  useEffect(() => {
    const adminFetchUsers = async () => {
      try {
        // console.log("admin context fetching users");
        const response = await Axios.get("/api/users", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setInitialized(true);
        setState((curState) =>
          produce(curState, (draft) => {
            draft.users = response.data;
          })
        );
      } catch (err) {
        console.error(err);
      }
    };

    if (!initialized) {
      if (token && claims?.admin) {
        adminFetchUsers();
      }
    }
  }, [token, initialized, setInitialized, setState, claims]);

  const deleteUser = async (uid: string) => {
    try {
      const response = await Axios.delete(`/api/users/${uid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("response :>> ", response);
      const response2 = await Axios.get("/api/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("response2 :>> ", response2);
      setState((curState) =>
        produce(curState, (draft) => {
          draft.users = response2.data;
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const value = {
    initialized,
    state,
    setState,
    deleteUser,
  };

  return <AdminContext.Provider value={value} {...props} />;
};

function useAdminContext() {
  return useContext(AdminContext)!;
}

export { AdminProvider, useAdminContext };
